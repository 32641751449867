<app-notification></app-notification>
<div class="matching">
  <div *ngIf="closed" class="closed">
    <div class="modal">
      <img class="pin" src="assets/images/pin.png" />
      <img class="lock" src="assets/images/lock.png" />
      <h1>Sorry, we’re closed!</h1>
      <p class="body-large">
        Please come back soon. <br />Opening hours: Mon-Fri, 7-23 CET / 1AM
        - 5PM EST.
      </p>
    </div>
  </div>
  <div class="top surface--2">
    <div class="max-width">
      <app-page-title [matching]="true" label="Matching"></app-page-title>
      <app-tab-buttons
        classList="body-large"
        [selected]="selectedSearchType"
        [tabButtons]="matchType"
        (callbackFunction)="chooseMatchType($event)"
      ></app-tab-buttons>
    </div>
  </div>
  <div *ngIf="loadingSearchType" class="loading">
    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>

  <div
    *ngIf="!dialog"
    class="content"
    [class]="{ loadingSearchType: loadingSearchType }"
  >
    <section class="surface--2 find-match" (clickOutside)="inputActive = ''">
      <div class="max-width">
        <app-find-match
          *ngIf="selectedSearchType === 0"
          [loading]="loading && !getLoading"
          [matchTypeNumber]="selectedSearchType"
          [matchType]="matchType"
          (cta)="findAction($event)"
          [advancedSearch]="searchContext"
          (callbackFunction)="customInput($event)"
          [selectedRequest]="getRequest(selectedRequest)"
          [manualRequest]="manualRequest"
          [isManual]="isManual"
        ></app-find-match>
        <app-find-match
          *ngIf="[1, 2].includes(selectedSearchType)"
          [loading]="loading && !getLoading"
          [matchTypeNumber]="selectedSearchType"
          [matchType]="matchType"
          (cta)="findAction($event)"
          [advancedSearch]="searchContext"
          (callbackFunction)="customInput($event)"
          [selectedconsultant]="selectedConsultant!"
        ></app-find-match>

        <!-- <div class="progress-bar">
          <div
            [class]="eachLoading !== -1 ? 'loading' + eachLoading : 'test'"
          ></div>
        </div> -->
      </div>
    </section>
    <section class="surface--2 results">
      <div class="max-width">
        <app-filters
          [selectedFilters]="selectedFilters"
          [disable]="loading"
          [selectedCvType]="selectedCvType"
          [iterateId]="iterateId"
          *ngIf="selectedSearchType !== 1"
          [matchId]="requestId"
          [selectedSearchType]="selectedSearchType"
          [selectedNav]="params ? params['slug'] : ''"
        ></app-filters>
        <div class="cards-list">
          <div class="the-list" *ngIf="response || getLoading">
            <div *ngIf="selectedSearchType !== 1" class="card">
              <div class="title-tab">
                <h3>Your results</h3>
                <!-- <p *ngIf="potentialResults" class="body-small textSecondary">
                  {{ potentialResults.length }} Matching consultant
                </p> -->
                <app-tab-buttons
                  *ngIf="featureFlags.isFeatureEnabled('filebasedmatching')"
                  classList="body-small small"
                  class="cvFile"
                  [disable]="loading"
                  [selected]="selectedCvType"
                  [tabButtons]="matchingType"
                  (callbackFunction)="cvFile($event)"
                ></app-tab-buttons>
              </div>
              <div *ngIf="selectedFilters.length > 0" class="selectedFilters">
                <ng-container
                  *ngFor="let filter of selectedFilters; let i = index"
                >
                  <ng-container
                    *ngIf="filter.type === 'relationstatus'; else displayName"
                  >
                    <app-tier-statuses
                      (callbackFunction)="removeFilter(filter)"
                      [tier]="getTier(filter)!"
                      [trailingIcon]="{ type: 'icon', icon: 'X' }"
                    ></app-tier-statuses>
                  </ng-container>
                  <ng-template #displayName>
                    <app-badge
                      [label]="filter.displayName"
                      [trailingIcon]="{ type: 'icon', icon: 'X' }"
                      color="neutral--Grey-300"
                      (callbackFunction)="removeFilter(filter)"
                    ></app-badge>
                  </ng-template>
                </ng-container>
                <p
                  *ngIf="selectedFilters.length > 0"
                  class="textLink clear-all body-small"
                  (click)="clearAll(true)"
                >
                  Clear all
                </p>
              </div>
              <app-level1
                [isManual]="isManual"
                [selectedCvType]="selectedCvType"
                *ngFor="let result of potentialResults | slice : 0 : loadLength"
                [consultant]="result"
                [iterateId]="iterateId"
                [entityId]="requestId"
                [selectedSearchType]="selectedSearchType"
              ></app-level1>
              <div
                *ngIf="loadLength < potentialResults.length"
                class="load-more"
              >
                <app-button
                  label="Load more"
                  (click)="loadMore()"
                  *ngIf="!moreLoading"
                ></app-button>
                <app-loader
                  [class]="['small']"
                  *ngIf="moreLoading"
                  [loading]="moreLoading"
                ></app-loader>
              </div>
            </div>

            <div *ngIf="potentialRequestResults.length > 0" class="card">
              <div>
                <h3>Your results</h3>
                <p class="body-small textSecondary">
                  {{ potentialRequestResults.length }} Matching requests
                </p>
              </div>
              <app-request-level1
                *ngFor="
                  let result of potentialRequestResults | slice : 0 : loadLength
                "
                [selectedRequest]="result"
              ></app-request-level1>
              <div
                *ngIf="loadLength < potentialRequestResults.length"
                class="load-more"
              >
                <app-button
                  label="Load more"
                  (click)="loadLength = loadLength + 10"
                ></app-button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="potentialListResults">
            <div *ngIf="potentialListResults.length > 0" class="the-list">
              <div class="list-status">{{ listStatus() }}</div>
            </div>
          </ng-container>

          <app-loader
            *ngIf="loading && !response"
            [loading]="loading && !response"
          ></app-loader>
          <div
            class="empty_matching textSecondary"
            *ngIf="!loading && !response"
          >
            <img src="/assets/images/empty_match.png" />
            <p class="s1">
              {{ matchType[selectedSearchType].emptyStatment?.title }}
            </p>
            <p class="body-large">
              {{ matchType[selectedSearchType].emptyStatment?.description }}
            </p>
          </div>
          <div
            class="empty_matching textSecondary"
            *ngIf="
              selectedSearchType === 0 &&
              potentialResults.length === 0 &&
              response
            "
          >
            <img src="/assets/images/no-info.png" />
            <p class="s1">No matching results</p>
            <p class="body-large">Try changing your filters"</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <app-dialog
    *ngIf="dialog"
    [requestData]="requestData"
    [type]="'request'"
    (close)="dialog = false"
  >
  </app-dialog>
</div>
